export const ar = {
  dashboard: {
    label: {
      overalview: 'ملخص',
      total_projects: 'إجمالي المساجد',
      total_campaigns: 'إجمالي الحملات',
      total_donors: 'إجمالي المانحين',
      total_vendors: 'إجمالي البائعين',
      total_operators: 'إجمالي مكاتب الإدارة',
      total_volunteers: 'إجمالي المتطوعين',
      total_money_donated: 'إجمالي الأموال المتبرع بها',
      total_money_paid: 'إجمالي الأموال المدفوعة',
      total_donation_for_this_project: 'إجمالي التبرع لهذا المسجد',
      total_money_donated_this_project: 'إجمالي الأموال التي يتم التبرع بها لحملات المسجد',
      total_people_donated_this_project: 'إجمالي الأشخاص الذين تبرعوا لهذا المسجد',
      total_people_donated_this_project_campaigns: 'إجمالي الأشخاص الذين تبرعوا لحملات المسجد',
      total_campaign_only_money_paid: 'إجمالي حملة الأموال المدفوعة فقط',
      total_vendor_income_paid: 'إجمالي دخل المقاولين المدفوع',
      total_donate_for_item: 'إجمالي الأموال التي يتم التبرع بها لهذا العنصر',
      total_needed_item: 'إجمالي العنصر المطلوب',
      total_provided_item: 'إجمالي العنصر المقدم',
      finished_milestones: 'مراحل الإنجاز النهائية',
      ongoing_milestones: 'مراحل الإنجاز المستمرة',
      total_milestones: 'مجموع مراحل الإنجاز',
      total_request_to_evaluate: 'طلب التقييم',
      mosque_evaluated: 'تقييم المسجد',
      archive_evaluated: 'تقييم الأرشيف',
      apply_evaluation: 'تطبيق التقييم',
      total_income: 'قيمة المسجد',
      finished_campaigns: 'الحملات المنتهية',
      ongoing_campaigns: 'الحملات المستمرة',
      total_amount_donation: 'إجمالي التبرعات',
      total_campaign: 'الحملات',
      total_amount_campaign: 'مقدار الحملات',
      total_project_item: 'عناصر المسجد',
      total_amount_project_item: 'سعر كامل عناصر المسجد',
      total_zakat: 'زكاة',
      total_amount_zakat: 'مقدار الزكاة',
      charts: {
        donation: 'مخطط التبرع',
        total_paid_money: ' مخطط لإجمالي الأموال المدفوعة',
        vendor_and_only_money: 'مخطط المقاول والمال المدفوع فقط',
        total_donation_project: 'مخطط إجمالي التبرعات للمسجد',
        total_donation_item_project: 'إجمالي التبرعات لهذا العنصر في هذا المسجد',
        DAILY: 'يومي',
        WEEKLY: 'أسبوعي',
        MONTHLY: 'شهري',
        YEARLY: 'سنوي',
        CAMPAIGN: 'حملة',
        ITEM: 'عنصر المسجد',
        SILVER: 'زكاة الفضة',
        GOLD: 'زكاة الذهب',
        MONEY: 'زكاة المال ',
        TRADE_GOODS: 'زكاة السلع التجارية',
        PROJECT: 'مسجد',
        MOSQUE: 'حملات طلبات الصيانة',
        BALANCE: 'توازن',
        DEPOSIT: 'إيداع',
        PURCASE: 'شراء',
      },
      duration: 'المدة',
      number_of_milestones: 'عدد مراحل الإنجاز',
      type: 'نوع التبرع',
      milestones: 'مراحل الإنجاز',
      invoices: 'الفواتير',
      total_income_until_today: 'قيمة المسجد حتى اليوم',
      only_money: 'المال فقط',
      popover: {
        description:
          'أموال الحملة فقط هي الأموال التي يدفع لك بها مكاتب الإدارة لشراء مواد وأشياء للحملة. هذه الأموال ليست راتب المقاول ويتعين على المقاول تحميل أي فواتير يشترونها للحملة في صفحة الفواتير.',
      },
      pay_to_vendor: 'دفع للمقاول',
      total_assigned_volunteers: 'متطوعون مخصصون',
      total_pending_volunteers: 'متطوعون معلقون',
      parent_campaign_name: 'اسم الحملة الأساسية',
      parent_project_name: 'اسم المسجد الأساسي',
      total_tickets: 'إجمالي التذاكر',
      solved_tickets: 'التذاكر المحلولة',
      average_handling: 'متوسط ​​وقت المعالجة',
      status: 'حالة',
      total_contribution: 'المساهمة الكلية',
      currently_campaigns: 'الحملات حاليا',
      total_remaining_amount: 'إجمالي المبلغ المتبقي',
      total_project: 'مجموع المشروع',
      total_amount_project: 'كمية المشاريع',
    },
  },
};
