export const en = {
  volunteer: {
    not_volunteer: 'You are not a volunteer!',
  },
  campaign_task: {
    full_request: 'Task is full/unavailable, all seats already taken!',
    approved_volunteer: 'You have already become a volunteer of this task!',
    already_applied:
      'You have already applied for this task, please wait for administrator to approve!',
    apply_request_not_found: 'Can not find request!',
    already_rejected: 'This request has been already rejected!',
  },
  ticket: {
    cannot_assign_ticket: 'Cannot assign ticket!',
    already_resolved: 'Ticket has been already resolved!',
  },
  product_tender: {
    not_vendor: 'Your account is not allowed for this request!',
    item_not_found: 'Marketplace item is not found!',
    invalid_item: 'Marketplace item is not vendor tender categories!',
    vendor_request_exists: 'You have existing request!',
    vendor_request_not_found: 'Vendor request is not found!',
    vendor_request_already_approved: 'Vendor request already approved!',
    vendor_request_already_rejected: 'Vendor request already rejected!',
  },

  invoice: {
    already_uploaded: 'You have upload receipt for this invoice!',
  },
};
