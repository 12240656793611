export const ar = {
  title: {
    campaign_milestone: 'إنجاز الحملة',
    add_milestone: 'أضف معلَم',
    anonymous_donor: 'مانح مجهول',
    donor: 'مانحين',
    is_on_the_way: 'المنجز',
    pending: 'قيد الانتظار',
    completed: 'مكتمل',
    milestone_details: 'تفاصيل معلَم',
    new_progress: 'طلب جديد',
    progresses: 'المراحل المنجزة',
    new_invoice: 'أضف فاتورة جديدة',
    detail_invoice: 'تفاصيل الفاتورة',
    enter_reply: 'إضافة رد',
  },
  conditions: {
    pending: 'معلقة',
    approved: 'معتمدة',
    published: 'منشورة',
    rejected: 'مرفوضة',
    finished: 'منتهية',
  },
  button: {
    add_milestone: 'إضافة معلَم',
    add_campaign: 'أضف حملة',
    submit_milestone: 'إرسال معلَم رئيسي',
    edit_campaign: 'تحرير الحملة',
    request_submit_campaign: ' تأكيد الحملة',
    request_submit_item: ' إرسال',
    save_campaign: 'حفظ الحملة',
    apply_for_campaign: 'تقدم بطلب للحصول على هذه الحملة',
    accept_this_vendor: 'قبول هذا المقاول',
    reject_this_vendor: 'رفض هذا المقاول',
    chat_with_super_admin: 'التحدث إلى المسؤول',
    chat_with_vendor: 'التحدث إلى المقاول',
    chat_with_operator: 'التحدث إلى مكتب الإدارة',
    send: 'ارسال',
    edit_reply: 'تعديل',
    delete_reply: 'حذف',
    milestones: {
      view_invoices: 'عرض الفواتير',
      add_progress: 'تقدم بطلب',
      view: 'عرض المعلَم',
      close_view: 'تأكيد',
      submit_progress: 'تأكيد ',
      cancel: 'إلغاء',
      check_progress: 'التحقق من الطلب',
      view_progress: 'عرض ',
      view_progresses: 'عرض ',
      confirm_progress: 'تأكيد ',
      reject_progress: 'رفض ',
      submit_invoice: 'إرسال الفاتورة',
      view_payment: 'عرض الدفع',
    },
    request_still_pending: 'هذا الطلب معلق',
  },
  form: {
    name: {
      label: 'اسم الحملة',
      required: 'اسم الحملة مطلوب',
    },
    project_id: {
      label: 'حدد المسجد الأصلي',
      required: ' المسجد الأصلي مطلوب',
    },
    campaign_type_id: {
      label: 'نوع الحملة',
      required: 'نوع الحملة مطلوب',
    },
    only_money: {
      label: 'حملة المال فقط',
      required: 'حملة المال فقط مطلوب',
      min_1: 'المبلغ  يجب أن يكون أكبر من 0',
    },
    vendor_income: {
      label: 'دخل المقاول',
      required: 'مطلوب دخل المقاول',
      min_1: 'يجب أن يكون دخل المقاول أكبر من 0',
      max_only_money: 'يجب أن يكون دخل المقاول متساوياً أو أقل من مبلغ المال فقط',
    },
    images: {
      label: 'تحميل الملفات',
      required: ' تحميل الملفات مطلوب',
      max_files: 'مسموح تحميل 3 ملفات فقط',
    },
    milestone_name: {
      label: 'اسم المعلَم',
      required: 'اسم الحدث الرئيسي مطلوب',
    },
    process_name: {
      label: 'اسم التقدم',
      required: 'اسم التقدم مطلوب',
    },
    deadline: {
      label: 'موعد التسليم',
      required: 'الموعد النهائي مطلوب',
    },
    details: {
      label: 'التفاصيل',
      required: 'التفاصيل مطلوبة',
    },
    progress_title: {
      label: 'عنوان التقدم',
      required: 'عنوان التقدم مطلوب',
    },
    milestone: {
      label: 'عدد المراحل',
    },
    type: {
      label: 'نوع التبرع',
    },
    amount: {
      label: 'المبلغ',
    },
    transaction_code: {
      label: 'رمز العملية',
    },
    date: {
      label: 'التاريخ',
    },
    donor_name: {
      label: 'اسم المانح',
    },
    donation: {
      label: 'هبة',
    },
    counts: {
      label: 'العدد',
    },
    email: {
      label: 'البريد الإلكتروني',
    },
    vendor_name: {
      label: 'اسم المقاول',
    },
    invoice_name: {
      label: 'اسم الفاتورة',
      required: 'اسم الفاتورة مطلوب',
    },
    invoice_amount: {
      label: 'المبلغ',
      min_1: 'يجب أن يكون أكبر من 1',
      required: 'المبلغ مطلوب',
      remaining_max: 'يجب أن يكون المبلغ الخاص بك أقل من',
    },
    bank_id: {
      label: 'اسم البنك',
      required: ' اسم البنك مطلوب',
    },
    bank_account_name: {
      label: 'اسم الحساب المصرفي',
      required: 'اسم الحساب المصرفي مطلوب',
    },
    bank_account_number: {
      label: 'رقم الحساب المصرفي',
      required: 'رقم الحساب المصرفي مطلوب',
      min: 'يجب أن يكون رقم الحساب المصرفي مؤلفاً من 22 رقماً ',
    },
  },
  options: {
    donors: {
      started_from: 'بدأت من',
      ends_to: 'انتهت ب ',
      money_donated: ' الأموال المتبرع بها',
      count_donated: 'إحصائيات التبرع',
    },
  },
};
