export const ar = {
  caption: {
    title: 'محفظة',
    create: 'إنشاء محفظة جديدة',
    detail_portfolio: 'تفاصيل الحافظة',
    status: {
      active: 'نشط',
      inactive: 'غير نشط',
    },
    add_new_portfolio: 'أضف محفظة مانحة جديدة',
    confirmation_delete_portfolio: 'هل أنت متأكد من أنك تريد حذف هذه المحفظة؟',
    donor_information: 'معلومات المانح',
    portfolio_information: 'معلومات الحافظة',
    select_donors: 'حدد واحد من المانحين',
    select: 'يختار',
    contact_information: 'معلومات الاتصال',
    realtime_balance: 'توازن في الوقت الحقيقي',
    campaign: {
      all_campaigns: 'جميع الحملات',
      currently_campaigns: 'الحملات حاليا',
      finished_campaigns: 'الحملات الانتهاء',
    },
    search: 'يبحث',
    sort_by: 'فرز حسب',
    latest: 'أحدث',
    oldest: 'أقدم',
    popular: 'شائع',
    filter: 'تصفية',
    balance_history: 'تاريخ التوازن',
    copied_notification: 'تم نسخ محفظة محفظة التفاصيل الخاصة بك',
    error_not_select_donor: 'يجب عليك تحديد مانح أولاً',
    deposit_amount: 'مبلغ الإيداع',
    transaction_history: 'تاريخ المعاملة',
    total_money_donated: 'إجمالي الأموال المتبرع بها',
    total_remaining_amount: 'إجمالي المبلغ المتبقي',
    notify_donor: 'أرسل إشعارًا إلى الجهة المانحة التي تمت إضافة مبلغ الوقف الجديد',
    make_donation: 'التبرع من هذه المحفظة',
    donation_details: 'تفاصيل التبرع',
    add_another_donation: 'أضف تبرعًا آخر',
  },
  button: {
    share_portfolio: 'حافظة مشاركة',
    delete_portfolio: 'حذف محفظة',
    create_portfolio: 'إنشاء محفظة جديدة',
    create_donor_portfolio: 'إنشاء محفظة مانحة',
    continue: 'يكمل',
    edit_portfolio: 'تحرير محفظة',
    add_endowment_amount: 'إضافة مبلغ الوقف',
    periodic_report: 'تقرير دوري',
    view_transaction_history: 'عرض تاريخ المعاملة',
    share: 'يشارك',
    delete: 'يمسح',
    apply: 'ابحث',
    reset_filter: 'إعادة تعيين التصفية',
    add_amount: 'أضف المبلغ',
    make_donations: 'قدم التبرعات',
    add_donation: 'أضف التبرع',
  },
  form: {
    portfolio_name: {
      label: 'اسم المحفظة',
      required: ' اسم المحفظة مطلوب',
    },
    creation_date: {
      label: 'تاريخ الإنشاء',
      required: ' تاريخ الإنشاء مطلوب',
    },
    balance: {
      label: 'الرصيد',
      required: 'الرصيد مطلوب',
      min_1: 'يجب أن يكون أكبر من 1',
    },
    status: {
      label: 'الحالة',
      required: 'الحالة مطلوبة',
    },
    type: {
      label: 'النوع',
      required: 'نوع الحافظة مطلوب',
    },
    region: {
      label: 'المنطقة',
      required: 'المنطقة مطلوبة',
    },
    full_name: {
      label: 'الاسم الكامل',
      required: 'الاسم الكامل مطلوب',
    },
    phone_number: {
      label: 'رقم الهاتف',
      required: 'رقم الهاتف مطلوب',
    },
    email: {
      label: 'البريد إلكتروني',
      required: 'البريد الإلكتروني مطلوب',
    },
    selected_reference: {
      label: 'المشروع / الحملة المراد دعمها',
      required: 'هذا الحقل لا يكون فارغا',
    },
    deposit_amount: {
      label: 'كمية',
      required: 'المبلغ مطلوب',
      max: 'يجب أن يكون المبلغ أقل من التوازن',
    },
    category: {
      label: 'فئة',
    },
    transaction_date: {
      label: 'تاريخ المعاملة',
    },
    remaining_amount: {
      label: 'المبلغ المتبقي',
    },
    donation_type: {
      label: 'حدد نوع التبرع',
      required: 'نوع التبرع مطلوب',
    },
    donation_item: {
      label: 'حدد عنصر',
      required: 'عنصر مطلوب',
    },
    donation_amount: {
      label: 'مبلغ التبرع',
      placeholder: 'أدخل مبلغ التبرع',
      required: 'مبلغ التبرع مطلوب',
    },
    wallet_name: {
      label: 'اختر المحفظة',
      placeholder: 'اختر المحفظة',
    },
    withdrawal_amount: {
      label: 'مبلغ السحب',
      placeholder: 'مبلغ السحب',
    },
    add: {
      label: 'إضافة محافظ أخرى',
      value: 'إضافة محافظ أخرى',
    },
  }
};
